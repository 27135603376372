import { AppsMenuType } from '@brainysoft/ui-layout';
import * as React from 'react';
import { useMemo } from 'react';
import { IconChartPie, IconCircles, IconCreditCard, IconDatabase, IconSettings, IconShield } from '@tabler/icons-react';
import { usePrimaryMenu } from './use-primary-menu';
import { AppsMenuItemType } from '@brainysoft/ui-layout';

const iconsMap: Record<string, JSX.Element> = {
  main: <IconCreditCard />,
  reports: <IconChartPie />,
  services: <IconShield />,
  dictionaries: <IconDatabase />,
  admin: <IconSettings />,
  'external-service': <IconCircles />,
};

export const useAppsMenu = () => {
  const q = usePrimaryMenu('/');

  const items: AppsMenuType = useMemo(
    () =>
      (q.data || []).map((r) => {
        const res: AppsMenuItemType = {
          title: r.label,
          name: r.name,
          route: r.href,
          icon: iconsMap[r.name] ? iconsMap[r.name] : <IconShield />,
        };
        return res;
      }),
    [q.data]
  );

  return { data: items, isLoading: q.isLoading };
};

// const appsNav: AppsMenuType = rootMenu.map(r => {
//     if (!iconsMap[r.name])
//         console.warn(`No icon for app: ${r.name}`);
//
//     return {
//         route: r.href,
//         name: r.name,
//         // icon: <span dangerouslySetInnerHTML={{__html: r.icon}}/>, добавляет отступы :(
//         icon: iconsMap[r.name],
//         title: r.label,
//     };
// });
