import { containerApiFactory } from '@brainysoft/react-nest-container';
import type { HttpRequestHeader } from 'antd/es/upload/interface';

export const v2Api = containerApiFactory();

export function makeHeadersWithCsrfTokenV2(): HttpRequestHeader {
  const token = readCookie('XSRF-TOKEN');

  if (!token) return {};

  return { 'X-XSRF-TOKEN': token };
}

export const v2baseUrl = '/api/v2';
export const coreProxy = containerApiFactory('/core-proxy/');
export const pfProxy = containerApiFactory('/print-forms-proxy/');

function readCookie(name: string) {
  const match = document.cookie.match(new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'));
  return match ? decodeURIComponent(match[3]) : null;
}
