import { setBaseApiUrl } from '@brainysoft/browser-api';
import axios from 'axios';
import { setAxiosInstance } from '@brainysoft/core-openapi/_custom-instance';

// settings base url, need to be imported first
setBaseApiUrl('/api/v2');

const coreProxyAxios = axios.create({
  baseURL: '/api/v2/core-proxy/',
});

// setAxiosInstance(coreProxyAxios as any);
setAxiosInstance(coreProxyAxios as any);
