export const getPlatform: () => 'spa' | 'app' | 'login' = () => window['__PLATFORM__'];

type SentrySettingsType = {
  dsn: string;
  tracesSampleRate: number;
  environment: string;
  release: string;
  user: {
    id: string;
  };
  tags: {
    commit: string;
    customer: string;
    uiSessionId: string;
  };
};

export const SentrySettings: SentrySettingsType | null = window['__SENTRY_SETTINGS__'] || null;
