import React from 'react';
import { AnnouncementModel } from '../models/Announcement.model';
import { DateTime } from '@brainysoft/ui-kit';
import { useAnnouncementMarkAsRead } from '../hooks/useAnnouncements';
import { useTranslation } from 'react-i18next';
import './announcements.css';

export interface AnnouncementProps {
  model: AnnouncementModel;
}

export function Announcement(props: AnnouncementProps) {
  const { t } = useTranslation('announcements', { useSuspense: true });
  const { mutate, isLoading } = useAnnouncementMarkAsRead(props.model);
  const model = props.model;
  return (
    <div className={`announcement-bar ${model.type}`}>
      <span style={{ float: 'left', marginRight: '1rem' }}>
        <span className={'date-wrap'}>
          <DateTime value={model.created_at * 1000} />
        </span>
      </span>
      <button onClick={() => mutate()} disabled={isLoading}>
        {t(`read`)}
      </button>
      {/*<PromiseButton*/}
      {/*  promise={mutateAsync}*/}
      {/*  type='default'*/}
      {/*  icon={'check'}*/}
      {/*  shape='round'*/}
      {/*  style={{ float: 'right', marginLeft: '1rem' }}*/}
      {/*>*/}
      {/*  {t(`${namespace}:read`)}*/}
      {/*</PromiseButton>*/}
      <span>{model.message}</span>
    </div>
  );
}
