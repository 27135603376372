import { Modals } from 'generouted/react-router-lazy';
import { useRouteError } from 'react-router';
import { Outlet } from 'react-router-dom';
import { LayoutContextProvider } from '../app/layout-context-provider';
import { PageErrorBoundary } from '@brainysoft/ui-layout';

export default function App() {
  return (
    <LayoutContextProvider>
      <Outlet />
      <Modals />
    </LayoutContextProvider>
  );
}

// export const Catch = (props: { error: Error; reset: () => void }) => {
export const Catch = (props: { error: Error; reset: () => void }) => {
  const err = useRouteError();
  console.log(props, err);
  console.log('Catched page-level error', props.error);
  return (
    <PageErrorBoundary>
      <Outlet />
    </PageErrorBoundary>
  );
};
