import { useClearCache } from '@brainysoft/react-nest-container';
import { useEffect } from 'react';
import { getPlatform } from '../environments/dom-constants';

/**
 * Для принудительной очистки, когда бэк хочет spa как страницу входа
 */
export function ForceCacheCleaner() {
  const clear = useClearCache(true, true);

  useEffect(() => {
    if (getPlatform() === 'login') {
      console.info('FORCE CLEAR CACHE (platform: login)');
      clear();
    }
  }, [clear]);

  return null;
}
