import localeData from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import dayJsRu from 'dayjs/locale/ru';
import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import {patchLocaleWithRuMasks} from "@brainysoft/ui-kit";

const BsRuLocale = patchLocaleWithRuMasks(localeData);

function applyRu() {
  dayjs.locale('ru');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore не знаю почему, но типы похожу не совпадают
  (localeData.DatePicker?.lang && (localeData.DatePicker.lang.shortMonths = dayJsRu.monthsShort.s)) ||
    console.warn('shortMonths not applied');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore не знаю почему, но типы похожу не совпадают
  (localeData.DatePicker?.lang && (localeData.DatePicker.lang.shortWeekDays = dayJsRu.weekdaysMin)) ||
    console.warn('shortWeekDays not applied');
}

export function LocaleContextProviderRu({ children }) {
  useEffect(() => {
    applyRu();
  }, []);

  return (
    <ConfigProvider locale={BsRuLocale}>
      {children}
    </ConfigProvider>
  );
}
