/* eslint-disable @typescript-eslint/no-inferrable-types */

export const BUILD_ID: string = 'dev-' + (+new Date()).toString(36);

export const BUILD_VERSION: string = 'dev';

export const IS_DEVELOPMENT = true;

export const IS_PRODUCTION = false;

if (window.location.pathname === '/ui-spa') {
  console.info('Redirecting to "/" (dev helper)');
  window.location.pathname = '/';
}
