import { MenuChild, usePrimaryMenu } from './use-primary-menu';
import { MenuType } from '@brainysoft/ui-layout';
import { useMemo } from 'react';

function unescapeHtml(text) {
  return `${text}`
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
}

function useMapMenu(items: MenuChild[] | undefined): MenuType {
  return useMemo(() => {
    if (!items) return [];

    return items.map((r) => {
      return r.divider
        ? {
            title: '',
            name: '',
            divider: true,
          }
        : {
            title: r.label,
            route: r.href,
            name: r.href + r.label,
            submenu: r.children?.map((r2: Record<string, any>) => {
              return r2.divider
                ? {
                    title: '',
                    name: '',
                    divider: true,
                  }
                : {
                    title: r2.label ? unescapeHtml(r2.label) : r2.label,
                    route: r2.href,
                    name: r2.label + r2.href,
                  };
            }),
          };
    });
  }, [items]);
}

export function useMainMenu(menuPath: string | undefined) {
  const { data } = usePrimaryMenu(menuPath);

  const firstPathPart = !menuPath ? undefined : menuPath.split('/').find((r) => !['', '3', '5'].includes(r));

  const active =
    firstPathPart === undefined
      ? undefined
      : data?.find((r) => {
          return r.name === firstPathPart;
        });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const children = active?.children;

  return useMapMenu(children);
}
