import { FC, PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';
import { v1Axios } from '@./shared/lib/api/v1-api';

function useLegacyVers() {
  return useQuery<string>(['@app', 'spa', 'layout', 'vers'], () => v1Axios.get('spa/layout/vers').then((r) => r.data), {
    cacheTime: 120 * 1000,
    staleTime: 60 * 1000,
  });
}

export const LegacyTechInfo: FC<PropsWithChildren> = () => {
  const { data } = useLegacyVers();
  if (!data) return <div></div>;
  return <div dangerouslySetInnerHTML={{ __html: data }}></div>;
};
