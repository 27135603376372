import { bsTheme as bsUiTheme, BsThemeType } from '@brainysoft/ui-theme';

export const bsTheme: BsThemeType = {
  ...bsUiTheme,
  // backgrounds: {
  //   whiteBackground: colors.white,
  //   primaryBackground: mix(colors.primary, colors.white, 92.5),
  //   redBackground: mix(colors.red, colors.white, 95),
  //   goldBackground: mix(colors.gold, colors.white, 95),
  //   blueBackground: mix(colors.blue, colors.white, 95),
  // },
  // general: {
  //   shadow: `0 0 30px ${rgba(bsUiTheme.black,.05)}`
  // },
  // borders: {
  //   primaryBorderColor: bsUiTheme.primary1,
  //   greyBorderColor: bsUiTheme.grey1,
  //   blueBorderColor: bsUiTheme.blue1,
  // }
};
