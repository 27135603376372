// НИ В КОЕМ СЛУЧАЕ НЕЛЬЗЯ ДОПУСКАТЬ ИМПОРТА NEST В SPA, ТОЛЬКО TYPE!!!
// Это приведет к публикации backend исходников, возможно секретов
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AppRouter as BackendAppRouter } from '../../../apps/ui-backend/src/trpc/init-trpc';
import { createTRPCReact } from '@trpc/react-query';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

export type AppRouter = BackendAppRouter;
export const trpc = createTRPCReact<AppRouter>();

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

// type a = RouterInput['funnel']['getInterFunnelRealtime'];
