import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';
import { loadBasicTranslations } from '@brainysoft/react-nest-container';
import { loadBasicLayoutTranslations } from '@brainysoft/ui-layout';
import { addBasicBundlesI18next } from '@brainysoft/hook-form-builder/lang/add-basic-bundles-i18next';
import { addFbPresetsBundlesI18next } from '@brainysoft/hook-form-builder-antd/lang/add-fb-presets-bundles-i18next';
import logFactory from 'debug';
import { NX_BUILD_VERSION } from './shared-const';
import { BUILD_ID, IS_DEVELOPMENT, IS_SPA } from '../utils/constants';

const debugLog = logFactory('spa:i18n');

debugLog('shared i18next initializer', { NX_BUILD_VERSION, BUILD_ID, IS_DEVELOPMENT });

function invalidateCache() {
  for (const key in localStorage) {
    if (key.startsWith('i18next_res_')) {
      localStorage.removeItem(key);
    }
  }
}

const LOCALE_VERSION = BUILD_ID;
const POSTFIX = IS_DEVELOPMENT ? '' : '?_b=' + encodeURIComponent(BUILD_ID);
const CACHE_TTL = IS_DEVELOPMENT ? 1 : 15 * 60 * 1000; // 15 minutes

const baseUrl = '/'; //document.baseURI;

const preloadNamespaces = IS_SPA ? ['componentsUi'] : ['basic', 'componentsUi'];

i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['htmlTag'],
    },

    fallbackLng: 'ru',

    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: CACHE_TTL,
          prefix: `i18next_res_`,
          defaultVersion: LOCALE_VERSION,
        },
        {
          loadPath: baseUrl + 'locales/{{lng}}/{{ns}}.json' + POSTFIX,

          // path to post report of missing resources
          addPath: IS_DEVELOPMENT ? 'http://127.0.0.1:4280/locales/missing/{{lng}}/{{ns}}' : '/api/v2/locales/missing/{{lng}}/{{ns}}',
        },
      ],
    },

    appendNamespaceToMissingKey: true,

    // saveMissing: IS_DEVELOPMENT,
    saveMissing: true,
    // missingKeyHandler: (lngs, ns, key) => {
    //   const msg = `[${lngs.join(', ')}] Missing lang key ${ns}:${key}`;
    //   if (IS_DEVELOPMENT) {
    //     console.warn(msg);
    //     toast.warn(msg, {
    //       toastId: msg,
    //       onClick: () => {
    //         invalidateCache();
    //         copy(`${ns}:${key}`);
    //       },
    //     });
    //   } else {
    //     // todo: придумать какуда отправлять чтобы было удобно работать и не ддосить бэк
    //     console.debug(msg);
    //   }
    // },
    preload: [],

    interpolation: {
      escapeValue: false,
    },

    defaultNS: 'default',
    nsSeparator: ':',
    ns: ['app', 'error', 'enum', ...preloadNamespaces],
    debug: false,

    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  });

// Принудительно запускаем загрузку ns error,
// тк loadBasicTranslations добавляет только минимальный набор ошибок
i18next.loadNamespaces(['error']);
loadBasicTranslations(i18next);
loadBasicLayoutTranslations(i18next);
addBasicBundlesI18next(i18next);
addFbPresetsBundlesI18next(i18next);

// window['i18n'] = i18next;

export const i18n = i18next;

if (IS_DEVELOPMENT) {
  window['i18nDeleteCache'] = invalidateCache;
}
