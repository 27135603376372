import css from './page-content-spinner.module.css';
import { FC, PropsWithChildren } from 'react';
import { PageSpinner } from './spinner/page-spinner';

export const PageContentSpinner: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={css['root']}>
      <PageSpinner />
    </div>
  );
};
