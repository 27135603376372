import { useQuery } from '@tanstack/react-query';
import { v1Api } from '../../api/v1-api';
import { IconHandStop } from '@tabler/icons-react';
import { Badge, Space } from 'antd';

interface BackendResponse {
  loanAppsInCosideration: number | undefined;
  tasks: number | undefined;
}

export function GlobalInformers() {
  const q = useQuery<BackendResponse>({
    queryKey: ['global-informers'],
    queryFn: () => v1Api.get('informers/refresh'),
    staleTime: 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000, // чтобы layout не дергался показываем предыдущее значение до 12 часов и в фоне обновляемся
  });

  return (
    <Space align={'center'} size={'small'} style={{ height: '100%' }}>
      {q.data?.loanAppsInCosideration != null && (
        <a href='/3/main/loan-apps/consideration'>
          <Badge count={q.data?.loanAppsInCosideration} size={'small'}>
            <IconHandStop size={18} stroke={1} />
          </Badge>
        </a>
      )}
    </Space>
  );
}
