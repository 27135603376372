import localeData from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { ConfigProvider } from 'antd';

function applyEn() {
  dayjs.locale('en');
}

export function LocaleContextProviderEn({ children }) {
  useEffect(() => {
    applyEn();
  }, []);

  return (
    <ConfigProvider locale={localeData}>
      {children}
    </ConfigProvider>
  );
}
