let _NX_BUILD_VERSION = null;

try {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  _NX_BUILD_VERSION = process.env.NX_BUILD_VERSION as string | undefined;
  // eslint-disable-next-line no-empty
} catch (e) {}

try {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  _NX_BUILD_VERSION = import.meta.env.NX_BUILD_VERSION as string | undefined;
  // eslint-disable-next-line no-empty
} catch (e) {}

export const NX_BUILD_VERSION = _NX_BUILD_VERSION;
