import React, { FC, PropsWithChildren } from 'react';
import { useUnreadAnnouncements, useUnreadAnnouncementsResult } from '../hooks/useAnnouncements';
import { Announcement } from '../components/announcement';

export const UnreadAnnouncementsContainer: FC<PropsWithChildren> = () => {
  const unread: useUnreadAnnouncementsResult = useUnreadAnnouncements();

  if (!unread.isSuccess || !unread.data) return null;

  return (
    <div>
      {unread.data.map((r) => (
        <React.Fragment key={r.id}>
          <Announcement model={r} />
        </React.Fragment>
      ))}
    </div>
  );
};
