import { useTranslation } from 'react-i18next';
import { NotFoundPage } from '@brainysoft/ui-layout';

export function NotFoundRoute() {
  const { t } = useTranslation('basic');
  return (
    <div>
      <NotFoundPage>
        <></>
      </NotFoundPage>
      <h1 style={{ textAlign: 'center' }}>{t('noPage')}</h1>
    </div>
    // {/*<h1>{t("routeNotFound")}</h1>*/}
  );
}

export default NotFoundRoute;
