import { FC, lazy, PropsWithChildren } from 'react';
import { IfEngymod } from '@brainysoft/ui-kit';
import { ReactQueryCacheCleaner } from './react-query-cache-cleaner';
// import { IS_DEVELOPMENT } from '../../environments/environment';

const LazyDevtools = lazy(() => import('./react-query-dev-tools'));

export const LazyReactQueryDevTools: FC<PropsWithChildren> = () => {
  const out = (
    <>
      <ReactQueryCacheCleaner />
      <LazyDevtools />
    </>
  );

  // todo исправить!
  // if (IS_DEVELOPMENT) return out;

  return <IfEngymod>{out}</IfEngymod>;
};
