import { FC, PropsWithChildren, useCallback } from 'react';
import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { toast, TypeOptions } from 'react-toastify';

const FloatContainer = styled.div({
  position: 'fixed',
  bottom: 0,
  zIndex: 100000,
  right: '100px',
  borderRadius: '6px 6px 0 0',
  overflow: 'hidden',
});

const FloatButton = styled.button({
  background: 'rgb(205, 205, 205)',
  color: '#3d3d3d',
  fontSize: '10px',
  border: 'none',

  '&:hover': {
    background: 'rgb(150, 150, 150)',
  },
});

const debugToast = (msg, type: TypeOptions = 'info') => {
  const fullMsg = `${msg}`;
  toast(fullMsg, { type });
  console.log(fullMsg);
};

export const ReactQueryCacheCleaner: FC<PropsWithChildren> = () => {
  const client = useQueryClient();

  const cl = useCallback(() => {
    client.clear();
    debugToast('Query cache cleared');
  }, [client]);

  const rf = useCallback(() => {
    debugToast('Refetch queries');
    client.refetchQueries().then(() => {
      // https://github.com/fkhadra/react-toastify/issues/594
      debugToast('Refetch queries done', 'success');
    });
  }, [client]);

  return (
    <FloatContainer>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <FloatButton onClick={cl}>Clear queries</FloatButton>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <FloatButton onClick={rf}>Refetch queries</FloatButton>
    </FloatContainer>
  );
};
