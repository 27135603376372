import { FC, PropsWithChildren } from 'react';
import { IconPresentation } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import css from './demo-login.module.css';
import cls from 'classnames';

export const DemoLogin: FC<PropsWithChildren> = () => {
  const { t } = useTranslation();

  if (!window['__DEMO_ENABLED__']) return null;

  return (
    <div className={'bs-login__form'}>
      <a href={'/login/demo'} className={cls(['bs-login__button', css['link']])}>
        {t('demoAccess')}
        <span className='bs-login__button-icon-wrapper'>
          <IconPresentation />
        </span>
      </a>
    </div>
  );
};
