import './app/api-initializer'; // settings base url, need to be imported first
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/app';

import '@brainysoft/ui-theme/index.css';
import '@brainysoft/ui-layout/index.css';
import '@brainysoft/react-nest-container/index.css';
import '@brainysoft/styles-general/antd/index.css';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
