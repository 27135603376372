import { Layout as BsLayout, RawLayout as BsRawLayout } from '@brainysoft/react-router-layout';
import { useMainMenu } from './menu/menu';
import * as React from 'react';
import { FC, PropsWithChildren, ReactNode, Suspense } from 'react';
import { UnreadAnnouncementsContainer } from '@./shared/lib/modules/announcements';
import { Affix, Col, Row, Space } from 'antd';
import { Outlet } from 'react-router';
import '@brainysoft/ui-kit/index.css';
import css from './layout.module.css';
import { PageContentSuspensed } from '@brainysoft/backoffice-sdk/components/page-content-suspensed';
import { GlobalInformers } from '@./shared/lib/modules/informers/global-informers';

interface LayoutProps extends PropsWithChildren {
  title?: string;
  search?: React.ReactNode;
  top?: React.ReactNode;
  menuPath?: string;
}

export const Layout: FC<LayoutProps> = ({ menuPath, children, top, search, title }) => {
  const m = useMainMenu(menuPath);

  return (
    <Suspense fallback={<BsLayout menu={m} />}>
      <BsLayout
        menu={m}
        search={search}
        top={
          <>
            {top}
            <GlobalInformers />
          </>
        }
        title={title}
      >
        <UnreadAnnouncementsContainer />
        {children}
      </BsLayout>
    </Suspense>
  );
};

export const RawLayout: FC<LayoutProps> = ({ menuPath, children, top, search, title }) => {
  const m = useMainMenu(menuPath);

  return (
    <Suspense fallback={<BsLayout menu={m} />}>
      <BsRawLayout
        menu={m}
        search={search}
        top={
          <>
            {top}
            <GlobalInformers />
          </>
        }
        title={title}
      >
        <UnreadAnnouncementsContainer />
        {children}
      </BsRawLayout>
    </Suspense>
  );
};

export const EntityLayout: FC<
  LayoutProps & {
    sidebar?: ReactNode;
    topbar?: ReactNode;
    actionsLeft?: ReactNode;
    actionsRight?: ReactNode;
    noOutlet?: boolean;
    disableWrap?: boolean;
  }
> = ({ menuPath, children, top, disableWrap, search, title, sidebar, topbar, actionsLeft, actionsRight, noOutlet }) => {
  const m = useMainMenu(menuPath);

  const content = sidebar ? (
    <Row align='top'>
      <Col span={16} style={{ paddingRight: '1rem' }}>
        {!noOutlet && <Outlet />}
        {children}
      </Col>
      <Col span={8} style={{ paddingLeft: '1rem' }}>
        {sidebar}
      </Col>
    </Row>
  ) : (
    <>
      {!noOutlet && <Outlet />}
      {children}
    </>
  );

  return (
    <Suspense fallback={<BsLayout menu={m} />}>
      <BsRawLayout
        menu={m}
        search={search}
        top={
          <>
            {top}
            <GlobalInformers />
          </>
        }
        title={title}
        disableWrap={true}
      >
        {(topbar || actionsLeft || actionsRight) && (
          <Affix>
            {/*<div className={'bs-layout__data-wrap'} style={{ paddingTop: 0, paddingBottom: 0, background: '#fff' }}>*/}
            <div style={{ background: '#fff' }}>
              {topbar}
              {(actionsLeft || actionsRight) && (
                <div className={'bs-layout__data-wrap'} style={{ borderBottom: '1px solid #eee', padding: '8px 16px' }}>
                  <div className={css['actions']}>
                    <Space align={'start'}>{actionsLeft}</Space>
                    <Space align={'start'}>{actionsRight}</Space>
                  </div>
                </div>
              )}
            </div>
          </Affix>
        )}
        <UnreadAnnouncementsContainer />
        <PageContentSuspensed>
          {disableWrap ? <>{content}</> : <div className={'bs-layout__data-wrap'}>{content}</div>}
        </PageContentSuspensed>
      </BsRawLayout>
    </Suspense>
  );
};

export const EntityPage: FC<
  PropsWithChildren<{
    sidebar?: ReactNode;
    topbar?: ReactNode;
    actionsLeft?: ReactNode;
    actionsRight?: ReactNode;
    noOutlet?: boolean;
  }>
> = ({ children, sidebar, topbar, actionsLeft, actionsRight, noOutlet }) => {
  const content = sidebar ? (
    <Row align='top'>
      <Col span={16} style={{ paddingRight: '1rem' }}>
        {!noOutlet && <Outlet />}
        {children}
      </Col>
      <Col span={8} style={{ paddingLeft: '1rem' }}>
        {sidebar}
      </Col>
    </Row>
  ) : (
    <>
      {!noOutlet && <Outlet />}
      {children}
    </>
  );

  return (
    <>
      {topbar && (
        <Affix>
          <div style={{ background: '#fff' }}>
            {topbar}
            {(actionsLeft || actionsRight) && (
              <div className={'bs-layout__data-wrap'} style={{ borderBottom: '1px solid #eee', padding: '8px 16px' }}>
                <div className={css['actions']}>
                  <Space align={'start'}>{actionsLeft}</Space>
                  <Space align={'start'}>{actionsRight}</Space>
                </div>
              </div>
            )}
          </div>
        </Affix>
      )}
      <div className={'bs-layout__data-wrap'}>{content}</div>
    </>
  );
};
