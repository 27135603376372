import { useEffect } from 'react';
import { SentrySettings as settings } from '../environments/dom-constants';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { routes } from 'generouted/react-router-lazy';

Sentry.init({
  dsn: settings?.dsn,
  release: settings?.release,
  environment: settings?.environment,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: settings?.tracesSampleRate || 0,
});

// console.log({ settings });

if (settings?.tags) {
  // console.log('Sentry/tags', settings.tags);
  Sentry.setTags(settings.tags);
}

if (settings?.user) {
  // console.log('Sentry/user', settings.user);
  Sentry.setUser(settings.user);
}

// setTimeout(() => {
//   Sentry.captureException(new Error(`Test ${new Date()}`));
// }, 1000);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const GeneroutedRouter = () => {
  return <RouterProvider router={sentryCreateBrowserRouter(routes)} />;
};

// export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
