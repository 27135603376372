import { FC, PropsWithChildren } from 'react';
import { useSessionInfo } from '@brainysoft/react-nest-container';
import { useQuery } from '@tanstack/react-query';
import { v2Api } from '@./shared/lib/api';
import { IS_DEVELOPMENT } from '../environments/environment';

export enum NecessityLevel {
  REMIND = 1,
  ASK = 2,
  FORCE = 10,
}

export const PasswordWatcher: FC<PropsWithChildren> = () => {
  const { data: ses } = useSessionInfo();
  const { data, isSuccess } = useQuery(
    ['@auth', 'pwd-score', ses?.sessionId || 'no'],
    () => v2Api.get<{ necessityLevel: NecessityLevel | null; passwordWeakness: string }>('auth/pwd-score'),
    {
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 10,
      enabled: !!ses?.sessionId,
      suspense: true,
    }
  );

  if (!isSuccess) return <div></div>;

  if (IS_DEVELOPMENT) return <div></div>;

  if (
    data?.necessityLevel &&
    data.necessityLevel >= NecessityLevel.ASK &&
    window.location.pathname !== '/user/password/change'
  ) {
    window.location.pathname = '/user/password/change';
  }

  return <div></div>;
};
