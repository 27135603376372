import { NX_BUILD_VERSION } from '../app/shared-const';

export const IS_SPA = window['__PLATFORM__'] !== undefined;
export const BUILD_ID = NX_BUILD_VERSION ?? 'dev-' + (+new Date()).toString(36);
export const IS_DEVELOPMENT = NX_BUILD_VERSION === undefined;

/**
 * stale time for data fetch queries, ms
 */
export const LONG_STALE = 3600000; //one hour
