import { useQuery } from '@tanstack/react-query';
import { v1Api } from '@./shared/lib/api/v1-api';

export interface MenuChild {
  href: string;
  active: boolean;
  label: string;
  permitted: boolean;
  divider?: boolean;
  children: MenuChild[] | null;
}

export interface PrimaryMenuItem {
  name: string;
  active: boolean;
  href: string;
  icon: string;
  label: string;
  permitted: boolean;
  children: MenuChild[];
}

export type PrimaryMenuResponse = PrimaryMenuItem[];

export function usePrimaryMenu(path: string | undefined) {
  return useQuery<PrimaryMenuResponse>(
    ['spa', 'layout', 'primary-menu', `path=${path}`],
    () =>
      v1Api.get<PrimaryMenuResponse>('spa/layout/primary-menu' + (path ? `?__path=${encodeURIComponent(path)}` : '')),
    {
      suspense: false,
      cacheTime: 5 * 60 * 1000,
      staleTime: path ? undefined : 60 * 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
}
