import { Layout } from '../app/layout';
import { NotFoundRoute } from '@./shared/lib/app/not-found-route';

export default function NotFound() {
  return (
    <Layout>
      <NotFoundRoute />
    </Layout>
  );
}
