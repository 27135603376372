import axios from 'axios';
import { AxiosApi, isAxiosError } from '@brainysoft/browser-api';
import { noopResponseInterceptor, toastErrorInterceptor } from '@brainysoft/react-nest-container';
import { ResponseError } from '@brainysoft/backoffice-sdk/utils/old-response-error';

export const v1Axios = axios.create({
  baseURL: '/api/',
  xsrfCookieName: 'XSRF-TOKEN-V1',
  xsrfHeaderName: 'X-CSRF-TOKEN',
});
v1Axios.interceptors.response.use(noopResponseInterceptor, toastErrorInterceptor);
v1Axios.interceptors.response.use(noopResponseInterceptor, (error) => {
  if (!isAxiosError(error)) {
    throw new Error('Unknown error type');
  }

  const data: any = error.response?.data ?? {};

  const responseError = new ResponseError(
    data.error,
    data.message,
    data.details,
    typeof data?.debug === 'object' ? data?.debug : null,
    data?.data?.activityLabel
  );

  return Promise.reject(responseError);
});

export const v1Api = new AxiosApi(v1Axios);
