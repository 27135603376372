import { FC } from 'react';

export interface Spinner2Props {
  size?: number;
  gap?: number;
}

/**
 * @deprecated todo reuse from monorepo
 * @param props
 * @constructor
 */
export const PageSpinner: FC<Spinner2Props> = (props) => {
  const { size = 6, gap = 2 } = props;
  const delay = 0.065;
  const delayMap = [1, 2, 3, 5, 2, 7, 6, 4, 0];

  return (
    <div className={'bs-spinner2__container'}>
      <div className={'bs-spinner2'} style={{ width: size * 3 + gap * 4, height: size * 3 + gap * 4 }}>
        {Array(9)
          .fill(null)
          .map((item, index) => {
            const style = {
              position: 'absolute',
              width: `${size}px`,
              height: `${size}px`,
              left: `${gap + ((index - (index % 3)) / 3) * (size + gap)}px`,
              top: `${gap + (index % 3) * (size + gap)}px`,
              animationDelay: `${delay * delayMap[index]}s`,
            };
            return <span key={index} className='bs-spinner2__block' style={style as any} />;
          })}
      </div>
    </div>
  );
};
