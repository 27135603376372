import { GeneroutedRouter } from './sentry';

export function AppRouter() {
  return (
    <>
      <GeneroutedRouter />
      {/*<SentryRoutes>*/}
      {/*  <Route path='/' element={<Navigate to={dashboardRoute()} />} />*/}
      {/*  <Route path='main'>*/}
      {/*    <Route element={<Layout />}>*/}
      {/*      <Route index element={<DashboardPage />} />*/}
      {/*      /!* eslint-disable-next-line i18next/no-literal-string *!/*/}
      {/*      <Route path='tasks' element={<div>tasks</div>} />*/}
      {/*      /!* eslint-disable-next-line i18next/no-literal-string *!/*/}
      {/*      <Route path='camunda-tasks' element={<div>camunda-tasks</div>} />*/}
      {/*    </Route>*/}
      {/*    /!*<Route path='clients' element={<ClientListPage />} />*!/*/}
      {/*    <Route path={'light'}>*/}
      {/*      <Route path={'clients/:clientId'}>*/}
      {/*        <Route path={'individual-offer'} element={<ClientIndividualOfferPage />} />*/}
      {/*      </Route>*/}
      {/*    </Route>*/}
      {/*    <Route path='clients'>*/}
      {/*      <Route element={<ClientsLayout />}>*/}
      {/*        <Route index element={<ClientsListPage />} />*/}
      {/*      </Route>*/}
      {/*      <Route element={<ClientOfferLayout />} path={':clientId'}>*/}
      {/*        <Route path={'individual-offer'} element={<ClientIndividualOfferPage />} />*/}
      {/*      </Route>*/}
      {/*    </Route>*/}
      {/*  </Route>*/}
      {/*  <Route path='3'>*/}
      {/*    <Route path='*' element={<RedirectToV3AndV5 />} />*/}
      {/*  </Route>*/}
      {/*  <Route path='5'>*/}
      {/*    <Route path='*' element={<RedirectToV3AndV5 />} />*/}
      {/*  </Route>*/}
      {/*  <Route path='b'>*/}
      {/*    <Route path='*' element={<RedirectToV3AndV5 />} />*/}
      {/*  </Route>*/}

      {/*  <Route*/}
      {/*    path='*'*/}
      {/*    element={*/}
      {/*      <Layout title={`404`}>*/}
      {/*        <h1>404</h1>*/}
      {/*      </Layout>*/}
      {/*    }*/}
      {/*  />*/}
      {/*</SentryRoutes>*/}
    </>
  );
}
