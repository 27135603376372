import { TRPCLink } from '@trpc/client';
import { observable } from '@trpc/server/observable';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import { isEngymodEnabled } from '@brainysoft/ui-kit';

import type { AppRouter } from '../../trpc';

const debugEnabled = isEngymodEnabled();

const activityLabelStyles = {
  fontSize: 12,
  opacity: 0.8,
  marginTop: 3,
};

// export const trpcToastLink: TRPCLink<AppRouter> = () => {
export const trpcToastLink: TRPCLink<AppRouter> = () => {
  // here we just got initialized in the app - this happens once per app
  // useful for storing cache for instance
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      // console.log('performing operation:', op);
      const unsubscribe = next(op).subscribe({
        next(value) {
          // console.log('we received value', value);
          observer.next(value);
        },
        error(err) {
          if (debugEnabled) {
            toast.error(
              <div>
                <h3>[TRPC] {err.shape?.message}</h3>
                <pre style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(err.shape, null, 1)}
                </pre>
                {err.shape?.data?.activityLabel && (
                  <div style={activityLabelStyles}>{err.shape?.data?.activityLabel}</div>
                )}
              </div>
            );
          } else {
            toast.error(
              <CopyToClipboard
                onCopy={() => alert('copied')}
                text={[
                  `Error: ${err.shape?.message}`,
                  err.shape?.data?.activityLabel ? `Activity label: ${err.shape?.data?.activityLabel}` : undefined,
                ]
                  .filter((r) => r)
                  .join('\n')}
              >
                <div>
                  <div>{err.shape?.message}</div>
                  {err.shape?.data?.activityLabel && (
                    <div style={activityLabelStyles}>{err.shape?.data?.activityLabel}</div>
                  )}
                </div>
              </CopyToClipboard>
            );
          }
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};
