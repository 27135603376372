/**
 * Имитирует строку, тк Promise.catch предусматривает отлавливание не только собственных исключений, но runtime и строк
 * Сравнивание кодов ошибок должно происходить через функцию is_error
 */
export class ResponseError extends Error {
  constructor(
    public code: string,
    public override message: string,
    public details: Record<string, unknown> | any = {},
    public debug: Record<string, unknown> | null = null,
    public activityLabel: string | null = null
  ) {
    super(message);
  }

  override toString() {
    return this.message;
  }
}

export function isResponseError(val): val is ResponseError {
  return val instanceof ResponseError;
}
