import { httpLink, loggerLink } from '@trpc/client';
import superjson from 'superjson';

import { isEngymodEnabled } from '@brainysoft/ui-kit';

import { trpc } from '../../trpc';
import { readCookie } from '../utils/read-cookie';
import { trpcToastLink } from './trpc-toast-link';

export const trpcClient = trpc.createClient({
  transformer: superjson,
  links: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isEngymodEnabled() ? loggerLink() : (undefined as any), // проверка на undefined ниже
    trpcToastLink,
    // httpBatchLink({
    httpLink({
      url: '/api/v2/trpc',
      headers() {
        return {
          'X-XSRF-TOKEN': readCookie('XSRF-TOKEN'),
        };
      },
    }),
  ].filter((r) => r !== undefined),
});
