import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AnnouncementModel } from '../models/Announcement.model';
import { v1Api, v1Axios } from '../../../api';

export type useUnreadAnnouncementsResult = UseQueryResult<AnnouncementModel[]>;
export const useUnreadAnnouncements: () => useUnreadAnnouncementsResult = () => {
  return useQuery(
    [`my/announcements/unread`],
    () => {
      // if (IS_STORYBOOK) {
      //   return mocker(`my/announcements/unread`, mock);
      // } else {
      return v1Axios.get('announcements/data').then((value) => value.data);
    },
    {
      refetchInterval: 60 * 1000,
      staleTime: 60 * 1000,
      cacheTime: 120 * 1000,
    }
  );
};

export type useLatestAnnouncementsResult = UseQueryResult<AnnouncementModel[]>;
export const useLatestAnnouncements: () => useLatestAnnouncementsResult = () => {
  return useQuery(
    [`my/announcements/latest`],
    () => {
      return v1Axios.get('announcements/latest').then((value) => value.data);
    },
    {
      refetchInterval: 60 * 1000,
      staleTime: 60 * 1000,
      cacheTime: 120 * 1000,
    }
  );
};

export const useAnnouncementMarkAsRead = (model: AnnouncementModel) => {
  const cache = useQueryClient();
  return useMutation(
    () => {
      // if (IS_STORYBOOK) return mocker('useAnnouncementMarkAsRead', [], 1000);
      // else
      return v1Api.post<AnnouncementModel[]>(`announcement/${model.id}/mark/read`, {});
    },
    {
      onSettled: () => cache.invalidateQueries([`my/announcements/unread`]),
    }
  );
};
